import { createRouter, createWebHistory } from "vue-router";
import Welcome from "../views/WelcomePage.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/",
    name: "Dashboard",
    component: () => import("../views/DashboardPage.vue"),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "",
        component: Welcome,
      },
      {
        path: "users",
        component: () => import("../views/users/UserList.vue"),
      },
      {
        path: "users/:uid/progress",
        component: () => import("../views/users/EditCourseProgress.vue"),
      },
      {
        path: "users/:uid",
        component: () => import("../views/users/EditUser.vue"),
      },
      {
        path: "courses",
        component: () => import("../views/courses/ViewCourses.vue"),
      },
      {
        path: "courses/:course",
        component: () => import("../views/courses/EditCourse.vue"),
      },
      {
        path: "courses/:course/subjects",
        component: () => import("../views/courses/ViewSubjects.vue"),
      },
      {
        path: "courses/:course/publish",
        component: () => import("../views/courses/PublishCourse.vue"),
      },
      {
        path: "courses/:course/:subject",
        component: () => import("../views/courses/EditSubject.vue"),
      },
      {
        path: "courses/:course/:subject/:topic",
        component: () => import("../views/courses/EditTopic.vue"),
      },
      {
        path: "courses/:course/:subject/topics",
        component: () => import("../views/courses/ViewTopics.vue"),
      },
      {
        path: "questions",
        component: () => import("../views/questions/QuestionsPage.vue"),
      },
      {
        path: "emails",
        component: () => import("../views/emails/EmailList.vue"),
      },
      {
        path: "emails/:email",
        component: () => import("../views/emails/EditEmail.vue"),
      },
      {
        path: "emails/:email/SendHistory",
        component: () => import("../views/emails/EmailSendHistory.vue"),
      },
      {
        path: "email_templates",
        component: () => import("../views/emails/EmailTemplateList.vue"),
      },
      {
        path: "email_templates/:emailtemplate",
        component: () => import("../views/emails/EditEmailTemplate.vue"),
      },
      {
        path: "extras",
        component: () => import("../views/extras/ExtrasPage.vue"),
      },
      {
        path: "extras/banner",
        component: () => import("../views/extras/EditBanner.vue"),
      },
      {
        path: "extras/news",
        component: () => import("../views/extras/EditNews.vue"),
      },
      {
        path: "extras/introVideo",
        component: () => import("../views/extras/EditIntroVideo.vue"),
      },
      {
        path: "/admin",
        component: () => import("../views/admin/AdministratorPage.vue"),
      },
    ],
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "NotFound",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

export default router;
